<template>
  <main class="justify-center sm:mx-auto sm:w-full sm:max-w-md">
    <div class="card w-full shadow-2xl bg-base-200">
      <div class="flex flex-col space-y-2 justify-center items-center pt-3">
        <div class="w-12 h-12 shadow">
          <img :src="url + '/logo.png'" :alt="sitename">
        </div>
        <div class="card-title mb-0 text-center">
          <a href="/" class="text-2xl"><b>{{ sitename }}</b></a>
        </div>
      </div>
      <div class="divider divide-base-300"></div>
      <div class="card-body pt-0">
        <p class="text-center text-primary-content">Please Activate Your License</p>
        <form @submit.prevent="activateLicense">
          <div class="form-control mt-5">
            <input type="text" placeholder="Domain" class="input input-accent" v-model="info.url" disabled>
          </div>
          <div class="form-control mt-2">
            <input type="text" placeholder="Purchase Code" class="input input-accent" v-model="token">
            <p v-if="info.er" class="mx-1 text-primary-content mt-4">Last Error : <strong class="text-error">{{info.er}}</strong></p>
          </div>
          <div class="form-control mt-6">
            <button type="submit" class="btn btn-primary" :disabled="loading">
              <svg-loading height="16px" width="16px" class="mr-1" v-if="loading"/>
              Activate
            </button>
          </div>
        </form>
      </div>

    </div>

  </main>
</template>

<script>
import axios from "axios";

export default {
  beforeCreate() {
    document.title = this.$route.meta.title + ' | ' + this.$sitename;
  },
  created() {
    this.getStatus();
  },
  data(){
    return {
      url: this.$config.FILE_URL,
      sitename: this.$sitename,
      info:{
        active:null,
        url:null,
        er: null,
      },
      token:'',
      errors:{},
      loading: false,
    }
  },
  computed: {
    logotext() {
      return this.$logotext;
    },
  },
  mounted() {
    this.$Progress.finish();
  },
  methods: {
    getStatus(){
      axios.get('/activated')
          .then(({data})=>{
            this.info = data;
            if (data.active === 1 || data.active === 2){
              this.$router.replace('/');
            }
          })
    },
    activateLicense(){
      this.loading = true;
      axios.post('/admin/activate',{token:this.token})
          .then(({data}) =>{
            this.$noti('success',data.message)
            this.$router.replace({name: 'dashboard'})
          })
          .catch((error)=>{
            this.$noti('error',error.response.data.message)
          })
      this.loading = false;
    },
  }
}
</script>
