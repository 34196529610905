<template>
  <main class="justify-center mx-auto">
    <div class="w-full shadow-2xl">
      <div class="p-2 bg-base-100 mx-1">
        <div class="flex flex-col">
          <div class="flex flex-row justify-between p-2 pb-0">
            <div class="card-title">Advertisements</div>
            <div class="btn btn-accent btn-xs" @click="getAds">Refresh</div>
          </div>
          <div class="divider mt-0">Filter</div>
          <div class="flex flex-row justify-around px-2">
            <div class="flex flex-col md:flex-row items-center space-y-2 md:space-x-5">
              <div class="font-bold">Status :</div>
              <div>
                <select class="select select-accent w-full max-w-xs" v-model="filter.status" @change="getAds">
                  <option value="10" selected>All</option>
                  <option value="0">Pending</option>
                  <option value="1">Active</option>
                  <option value="2">Completed</option>
                  <option value="3">Rejected</option>
                  <option value="4">On Hold</option>
                </select>
              </div>
            </div>
            <div class="flex flex-col md:flex-row items-center space-y-2 md:space-x-5">
              <div class="font-bold">SortBy :</div>
              <div>
                <select class="select select-accent w-full max-w-xs" v-model="filter.sortBy" @change="getAds">
                  <option value="desc" selected>Latest</option>
                  <option value="asc">Oldest</option>
                </select>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="card-body p-1 pb-6">
        <div v-if="isLoading" class="mt-5">
          <content-placeholders :centered="true" :rounded="true">
            <content-placeholders-heading/>
            <content-placeholders-heading/>
            <content-placeholders-heading/>
            <content-placeholders-heading/>
            <content-placeholders-heading/>
          </content-placeholders>
        </div>
        <div v-else>
          <div v-if="adsData.total > 0">
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-3">
              <div v-for="(ad, index) in adsData.data" :key="index">
                <div class="card p-2 shadow-lg compact bg-base-100 mx-1 mt-3 mb-2">
                  <div class="flex-row items-center space-x-2.5 card px-1 pb-1">
                    <div class="flex-1">
                      <div class="flex-row items-center space-x-3 card mt-1 mb-2">
                        <div class="avatar">
                          <div class="rounded-full w-10 h-10 shadow clickable" @click="viewUser(ad.user.username)">
                            <img v-if="ad.user.image" :src="url + '/' + ad.user.image"/>
                            <img v-else :src="url + '/user/no-image.png'"/>
                          </div>
                        </div>
                        <div class="flex flex-row space-x-1.5 items-center">
                          <div class="font-bold text-lg ml-1 clickable" @click="viewUser(ad.user.username)">
                            {{ ad.user.name }}
                          </div>
                          <div v-if="ad.user.verified">
                            <verification-svg height="17px" width="17px"/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex-0">
                      <div class="flex-row items-center space-x-2.5 card mt-1 mb-2">
                        <h2 class="text-sm">{{ ad.created_at | fromNow }}</h2>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col justify-around space-y-2">
                    <div class="avatar self-center mb-2">
                      <div class="w-80 rounded">
                        <figure>
                          <img v-if="ad.image" :src="url + ad.image"/>
                          <img v-else :src="url + '/user/no-image.png'"/>
                        </figure>
                      </div>
                    </div>

                    <div class="flex flex-row justify-between space-x-3 mx-1">
                      <div class="font-bold">Status :</div>
                      <div>
                        <div v-if="ad.stat === 0" class="badge badge-warning">Pending</div>
                        <div v-if="ad.stat === 1" class="badge badge-success">Active</div>
                        <div v-if="ad.stat === 2" class="badge badge-info">Completed</div>
                        <div v-if="ad.stat === 3" class="badge badge-error">Rejected</div>
                        <div v-if="ad.stat === 4" class="badge badge-secondary">On Hold</div>
                      </div>
                    </div>

                    <div class="flex flex-row justify-between items-center space-x-3 mx-1">
                      <div class="font-bold">Type :</div>
                      <div class="flex flex-row space-x-1 items-center">
                        <div v-if="ad.type === 1">Pay Per View</div>
                        <div v-if="ad.type === 2">Pay Per Day</div>
                      </div>
                    </div>


                    <div class="flex flex-row justify-between items-center space-x-3 mx-1">
                      <div class="font-bold">Location :</div>
                      <div class="flex flex-row space-x-1 items-center">
                        <div v-if="ad.location === 0">Feed + Banner</div>
                        <div v-if="ad.location === 1">In Feed</div>
                        <div v-if="ad.location === 2">Banner</div>
                      </div>
                    </div>


                    <div class="flex flex-row justify-between items-center space-x-3 mx-1">
                      <div class="font-bold">Views :</div>
                      <div class="flex flex-row space-x-1 items-center">
                        <div>{{ ad.views }}</div>
                      </div>
                    </div>

                    <div class="flex flex-row justify-between items-center space-x-3 mx-1">
                      <div class="font-bold">Clicks :</div>
                      <div class="flex flex-row space-x-1 items-center">
                        <div>{{ ad.clicks }}</div>
                      </div>
                    </div>

                    <div class="flex flex-row justify-between items-center space-x-3 mx-1">
                      <div class="font-bold">Cost :</div>
                      <div class="flex flex-row space-x-1 items-center">
                        <coin-svg height="15px" width="15px"/>
                        <div class="text-base font-semibold">
                          {{ ad.cost }}
                        </div>
                      </div>
                    </div>


                  </div>

                  <div class="flex flex-row justify-center space-x-3 p-2 rounded pt-3 mt-1">
                    <div class="btn btn-primary btn-sm" @click="clickAction(ad)">
                      <i class="bx bx-edit"></i>
                    </div>

                    <div class="btn btn-success btn-sm" v-if="ad.stat === 0" @click="confirmButtonClick(ad, 1)">
                      <i class="bx bx-check"></i>
                    </div>
                    <div class="btn btn-error btn-sm" v-if="ad.stat === 0" @click="confirmButtonClick(ad, 2)">
                      <i class="bx bx-x"></i>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div
                class="card p-2 shadow-lg compact text-center bg-base-100 mx-1 mt-3 mb-2"
            >
              <h2 class="font-bold text-lg">Nothing to Show</h2>
            </div>
          </div>
        </div>
        <div class="flex justify-center mt-2">
          <pagination class="menu menu-horizontal bg-base-100 rounded-box p-0" :data="adsData" :limit="3"
                      @pagination-change-page="getAds"></pagination>
        </div>
      </div>
    </div>

    <modal :showing="showModal" :if-close="true" @update:modal="showModal = $event">

      <div v-if="showModal" class="card p-2 shadow-lg compact bg-base-100 mx-1 mt-1">
        <div class="divider">Advertisement Details</div>

        <div class="form-control font-bold mt-3" v-if="selectedInfo.image">
          <div class="flex flex-col text-left">
            <div class="mb-2">Feed Image:</div>
            <div class="flex justify-center items-center bg-base-200">
              <div class="avatar self-center mb-2">
                <div class="w-80 rounded">
                  <figure>
                    <img :src="url + selectedInfo.image" :alt="selectedInfo.title"/>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-control font-bold mt-2" v-if="selectedInfo.banner">
          <div class="flex flex-col text-left">
            <div class="mb-2">Banner :</div>
            <div class="flex justify-center items-center bg-base-200">
              <div class="avatar self-center mb-2">
                <div class="w-80 rounded">
                  <figure>
                    <img :src="url + selectedInfo.banner" :alt="selectedInfo.title"/>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-control font-bold mt-3">
          <div class="flex flex-row justify-between">
            <div class="mr-2">Title :</div>
            <div class="text-right text-sm" v-if="selectedInfo.title && selectedInfo.title.length > 80">
              {{ selectedInfo.title }}
            </div>
            <div v-else class="text-right">
              {{ selectedInfo.title }}
            </div>
          </div>
        </div>

        <div class="form-control font-bold mt-2">
          <div class="flex flex-row justify-between items-center">
            <div>Type :</div>
            <div class="flex flex-row space-x-1 items-center">
              <div v-if="selectedInfo.type === 1">Pay Per View</div>
              <div v-if="selectedInfo.type === 2">Pay Per Day</div>
            </div>
          </div>
        </div>

        <div class="form-control font-bold mt-2">
          <div class="flex flex-row justify-between items-center">
            <div>Location :</div>
            <div class="flex flex-row space-x-1 items-center">
              <div v-if="selectedInfo.location === 0">Feed + Banner</div>
              <div v-if="selectedInfo.location === 1">In Feed</div>
              <div v-if="selectedInfo.location === 2">Banner</div>
            </div>
          </div>
        </div>

        <div class="form-control font-bold mt-2" v-if="selectedInfo.type === 1">
          <div class="flex flex-row justify-between items-center">
            <div>Amount :</div>
            <div class="flex flex-row space-x-1 items-center">
              <div>{{ selectedInfo.max_views }} Views</div>
            </div>
          </div>
        </div>

        <div class="form-control font-bold mt-2" v-if="selectedInfo.type === 2">
          <div class="flex flex-row justify-between items-center">
            <div>Duration :</div>
            <div class="flex flex-row space-x-1 items-center">
              <div>{{ selectedInfo.days }} Days</div>
            </div>
          </div>
        </div>

        <div class="form-control font-bold mt-2" v-if="selectedInfo.type === 2 && selectedInfo.expire_at">
          <div class="flex flex-row justify-between items-center">
            <div>Expire Date :</div>
            <div class="flex flex-row space-x-1 items-center">
              <div>{{ selectedInfo.expire_at | fromNow }}</div>
            </div>
          </div>
        </div>

        <div class="form-control font-bold mt-2">
          <div class="flex flex-row justify-between items-center">
            <div>Cost :</div>
            <div class="flex flex-row space-x-1 items-center">
              <coin-svg height="15px" width="15px"/>
              <div class="text-base font-semibold">
                {{ selectedInfo.cost }}
              </div>
            </div>
          </div>
        </div>

        <div class="form-control font-bold mt-2">
          <div class="flex flex-row justify-between items-center">
            <div>Status :</div>
            <div class="flex flex-row space-x-1 items-center">
              <div v-if="selectedInfo.stat === 0" class="badge badge-warning">Pending</div>
              <div v-if="selectedInfo.stat === 1" class="badge badge-success">Active</div>
              <div v-if="selectedInfo.stat === 2" class="badge badge-info">Completed</div>
              <div v-if="selectedInfo.stat === 3" class="badge badge-error">Rejected</div>
              <div v-if="selectedInfo.stat === 4" class="badge badge-secondary">On Hold</div>
            </div>
          </div>
        </div>

        <div class="form-control font-bold mt-2">
          <div class="flex flex-row justify-between items-center">
            <div>Views :</div>
            <div class="flex flex-row space-x-1 items-center">
              <div>{{ selectedInfo.views }}</div>
            </div>
          </div>
        </div>

        <div class="form-control font-bold mt-2">
          <div class="flex flex-row justify-between items-center">
            <div>Clicks :</div>
            <div class="flex flex-row space-x-1 items-center">
              <div>{{ selectedInfo.clicks }}</div>
            </div>
          </div>
        </div>

        <div class="form-control font-bold mt-2">
          <div class="flex flex-row justify-between items-center">
            <div>Url :</div>
            <div class="flex flex-row space-x-1 items-center">
              <div v-if="selectedInfo.url">{{ selectedInfo.url }}</div>
              <div v-if="selectedInfo.status_id">Status</div>
              <div v-else>N/A</div>
            </div>
          </div>
        </div>

        <div class="form-control font-bold mt-2">
          <div class="flex flex-row justify-between items-center">
            <div>Created :</div>
            <div class="flex flex-row space-x-1 items-center">
              <div class="text-sm">{{ selectedInfo.created_at | dateFormat }}</div>
            </div>
          </div>
        </div>

        <div v-if="selectedInfo.stat != 0" class="form-control font-bold mt-2">
          <div class="flex flex-col text-left space-y-2">
            <div>Update Status :</div>
            <div>
              <select class="select select-accent w-2/3" v-model="adStatus">
                <option value="" selected disabled>Select Action</option>
                <option :value="1">Active</option>
                <option :value="2">Completed</option>
                <option :value="4">On Hold</option>
              </select>
            </div>
          </div>

        </div>

        <div class="mt-8 mb-3">
          <div class="flex justify-center" v-if="selectedInfo.stat != 0">
            <div class="form-control w-1/3">
              <button @click="updateAd()" class="btn btn-primary" :disabled="isUpdating">
                <svg-loading height="16px" width="16px" class="mr-1" v-if="isUpdating"/>
                Update
              </button>
            </div>
          </div>
          <div class="flex flex-row justify-center space-x-4" v-else>
            <div class="form-control">
              <button @click="confirmButtonClick(selectedInfo, 1)" class="btn btn-success" :disabled="isUpdating">
                <svg-loading height="16px" width="16px" class="mr-1" v-if="isUpdating"/>
                Approve
              </button>
            </div>
            <div class="form-control">
              <button @click="confirmButtonClick(selectedInfo, 2)" class="btn btn-error" :disabled="isUpdating">
                <svg-loading height="16px" width="16px" class="mr-1" v-if="isUpdating"/>
                Reject
              </button>
            </div>
          </div>
        </div>

      </div>
    </modal>

    <modal :if-close="true" :showing="confirmModal" @update:modal="confirmModal = $event">
      <div v-if="confirmModal" class="card p-2 shadow-lg compact bg-base-100 mx-1 mt-1">
        <div class="divider">Are You Sure ?</div>
        <div class="text-xl mt-5" v-if="modalType === 1">
          Approve : {{ selectedInfo.title }} from {{ selectedInfo.user.name }} !<span> </span>
        </div>
        <div class="text-xl mt-5" v-if="modalType === 2">
          Reject : {{ selectedInfo.title }} from {{ selectedInfo.user.name }} !<span> </span>
        </div>

        <div class="form-control mt-8 mb-3">
          <div class="flex flex-row space-x-5 justify-center">
            <button :disabled="isUpdating" class="btn btn-outline" @click="confirmModal = false">
              Cancel
            </button>
            <button :disabled="isUpdating" class="btn btn-success" v-if="modalType === 1" @click="approveAd()">
              <svg-loading v-if="isUpdating" class="mr-1" height="16px" width="16px"/>
              Approve
            </button>
            <button :disabled="isUpdating" class="btn btn-error" v-if="modalType === 2" @click="rejectAd()">
              <svg-loading v-if="isUpdating" class="mr-1" height="16px" width="16px"/>
              Reject
            </button>
          </div>
        </div>
      </div>
    </modal>

  </main>
</template>

<script>
import axios from "axios";

export default {
  beforeCreate() {
    document.title = this.$route.meta.title + " | " + this.$sitename;
  },
  created() {
    this.getAds();
  },
  mounted() {
    this.$Progress.finish();
  },
  data() {
    return {
      url: this.$config.FILE_URL,
      adsData: {},
      selectedInfo: {},
      modalType: null,
      filter: {
        status: '10',
        sortBy: 'desc',
      },
      adStatus: "",
      isLoading: false,
      isUpdating: false,
      errors: {},
      showModal: false,
      confirmModal: false,
    };
  },
  methods: {
    getAds(page = 1) {
      this.isLoading = true;
      let status = this.filter.status;
      if (status !== '') {
        status = parseInt(status)
        if (status === 10) {
          status = ''
        }
      }
      let sortBy = this.filter.sortBy;
      let urlData = {params: {status: status, sortBy: sortBy, page: page}};
      axios.get('/admin/ads', urlData)
          .then(({data}) => {
            this.adsData = data;
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            this.errors = error.response.data.errors;
          });
    },
    clickAction(data) {
      try {
        this.selectedInfo = data;
        if (data.stat != 0) {
          this.adStatus = data.stat;
        }
      } finally {
        this.showModal = true;
      }
    },
    async approveAd() {
      this.isUpdating = true;
      try {
        let r = await axios.post('admin/ad-status', {id: this.selectedInfo.id, status: 1, approve: 1});
        this.isUpdating = false;
        this.getAds();
        this.$noti('success', '' + r.data.message + '');
        this.confirmModal = false;
      } catch (e) {
        this.isUpdating = false;
        this.$noti('error', 'Something went wrong')
      }
    },

    async rejectAd() {
      this.isUpdating = true;
      try {
        let r = await axios.post('admin/ad-status', {id: this.selectedInfo.id, status: 3});
        this.isUpdating = false;
        this.getAds();
        this.$noti('success', '' + r.data.message + '');
        this.confirmModal = false;
      } catch (e) {
        this.isUpdating = false;
        this.$noti('error', 'Something went wrong')
      }
    },

    async updateAd() {
      this.isUpdating = true;
      try {
        let r = await axios.post('admin/ad-status', {id: this.selectedInfo.id, status: this.adStatus});
        this.isUpdating = false;
        this.getAds();
        this.$noti('success', '' + r.data.message + '');
        this.showModal = false;
      } catch (e) {
        this.isUpdating = false;
        this.$noti('error', 'Something went wrong')
      }
    },

    confirmButtonClick(data, type) {
      try {
        this.selectedInfo = data;
        this.modalType = type;
      } finally {
        this.confirmModal = true;
      }
    },
	
	viewUser(username) {
      this.$router.push({
        name: "user-details",
        params: { username: username },
      });
    },

  },
};
</script>

<style scoped></style>