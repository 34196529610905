<template>
  <main v-if="infoLoading">
    <content-placeholders :centered="true" :rounded="true">
      <content-placeholders-img/>
      <content-placeholders-heading/>
      <content-placeholders-heading/>
    </content-placeholders>
  </main>
  <main v-else class="justify-center mx-auto w-full max-w-4xl">
    <div class="card p-2 shadow-lg compact bg-base-100 mx-1 mt-1">
      <figure>
        <img
            v-if="status.media.url && status.type === 'image'"
            :alt="status.title"
            :class="
            fullImage
              ? 'status-image-full'
              : 'status-image md:status-image-desktop'
          "
            :src="url + status.media.url"
            class="object-fill w-full rounded"
            @click="toggleHeight"
        />

        <vue-plyr v-if="status.media.url && status.type === 'video'">
          <video :data-poster="url + status.thumb" controls>
            <source
                :src="url + status.media.url"
                type="video/mp4"
            />
          </video>
        </vue-plyr>

        <vue-plyr v-if="status.media.url && status.type === 'youtube'">
          <div
              :data-plyr-embed-id="status.media.ext"
              data-plyr-provider="youtube"
          ></div>
        </vue-plyr>
      </figure>
      <router-link :to="{name: 'status-edit', params: {code: status.code}}"
                   class="btn btn-primary btn-sm mt-2 self-center">Edit Status
      </router-link>
      <div
          class="flex flex-row justify-between bg-base-300 p-1 rounded px-2 mt-2"
      >
        <div class="flex mt-1 items-center">
          <eye-svg class="text-lg"></eye-svg>
          <h2 class="font-bold text-lg ml-2">
            {{ status.views | formatNumber }}
          </h2>
        </div>
        <div class="flex mt-1 items-center">
          <span><i class="bx bx-heart text-lg"></i> </span>
          <h2 class="font-bold text-lg ml-2">
            {{ status.likes | formatNumber }}
          </h2>
        </div>
        <div class="flex mt-1 items-center">
          <span><i class="bx bx-comment text-lg"></i> </span>
          <h2 class="font-bold text-lg ml-2">
            {{ status.comments | formatNumber }}
          </h2>
        </div>
      </div>
      <div class="p-2 pb-1">
        <h1 class="text-base font-medium">{{ status.title }}</h1>
      </div>
    </div>

    <div v-if="status.user" class="card p-2 compact bg-base-100 mx-1 mt-1">
      <div class="flex-row items-center space-x-2.5 card px-1 pb-1">
        <div class="flex-1">
          <div class="flex-row items-center space-x-3 card mt-1 mb-1">
            <div class="avatar">
              <div
                  class="rounded-full w-10 h-10 shadow clickable"
                  @click="viewUser(status.user.username)"
              >
                <img
                    v-if="status.user.image"
                    :src="url + '/' + status.user.image"
                />
                <img v-else :src="url + '/user/no-image.png'"/>
              </div>
            </div>
            <div class="flex flex-col space-y-0 items-start">
              <div
                  class="flex flex-row space-x-1.5 items-center clickable"
                  @click="viewUser(status.user.username)"
              >
                <div class="font-bold text-lg ml-1">
                  {{ status.user.name }}
                </div>
                <div v-if="status.user.verified">
                  <verification-svg height="17px" width="17px"/>
                </div>
              </div>
              <div class="text-sm font-thin text-opacity-60 ml-1">
                @{{ status.user.username }}
              </div>
            </div>
          </div>
        </div>
        <div class="flex-0">
          <div class="flex-row items-center space-x-2.5 card mt-1 mb-1">
            <router-link
                :to="{
                name: 'user-details',
                params: { username: status.user.username },
              }"
                class="btn-secondary btn-sm"
            >View Profile
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="card p-2 compact bg-base-100 mx-1 mt-1">
      <div class="flex flex-col space-y-2">
        <div
            class="flex flex-row justify-between space-x-3 mx-1"
            v-if="categories && categories.length"
        >
          <div class="font-bold">Categories :</div>
          <div
              class="flex flex-wrap justify-end items-center space-x-2 space-y-1"
          >
            <div
                class="badge badge-outline"
                v-for="(category, index) in categories"
                :key="index"
            >
              {{ category.name }}
            </div>
          </div>
        </div>
        <div
            class="flex flex-row justify-between space-x-3 mx-1"
            v-if="languages && languages.length"
        >
          <div class="font-bold">Languages :</div>
          <div
              class="flex flex-wrap justify-end items-center space-x-2 space-y-1"
          >
            <div
                class="badge badge-outline"
                v-for="(language, index) in languages"
                :key="index"
            >
              {{ language.name }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="collapse bg-base-100 w-full border rounded-box border-base-300 collapse-arrow mt-1">
      <input type="checkbox"/>
      <div class="collapse-title text-xl font-medium">Descriptions</div>
      <div class="collapse-content">
        <p v-if="status.description" class="ml-2" style="white-space: pre">
          {{ status.description }}
        </p>
      </div>
    </div>

    <div class="mx-2 mt-4 mb-4">
      <div class="flex flex-row w-full justify-between">
        <div
            :class="formType === 1 ? 'tab-active' : ''"
            class="flex-grow tab tab-bordered"
            @click="tabClick(1)"
        >
          Comments
        </div>
        <div
            :class="formType === 2 ? 'tab-active' : ''"
            class="flex-grow tab tab-bordered"
            @click="tabClick(2)"
        >
          Transactions
        </div>
      </div>
    </div>

    <div class="mt-3" v-if="formType === 1">
      <div v-for="(comment, index) in comments.data" :key="index">
        <div class="card shadow-lg compact side bg-base-100 pb-0 mb-1">
          <div class="flex flex-row items-center space-x-4 card-body pt-1 pb-0">
            <div class="flex flex-row items-center">
              <div class="avatar">
                <div class="rounded-full w-10 h-10 shadow clickable" @click="viewUser(comment.user.username)">
                  <img v-if="comment.user.image" :alt="comment.user.name" :src="url + '/' + comment.user.image"/>
                  <img v-else :alt="comment.user.name" :src="url + '/user/no-image.png'"/>
                </div>
              </div>
            </div>
            <div class="flex flex-row w-full justify-between items-center">
              <div>
                <div class="flex flex-row items-center">
                  <div class="card-title text-sm clickable" @click="viewUser(comment.user.username)">
                    {{ comment.user.name }}
                  </div>
                  <div v-if="comment.user.verified" class="ml-1">
                    <verification-svg height="13px" width="13px"/>
                  </div>
                  <div class="text-xs font-thin text-opacity-30 ml-2">
                    {{ comment.created_at | fromNow }}
                  </div>
                </div>
                <p class="text-base-content text-opacity-95">
                  {{ comment.content }}
                </p>
              </div>

              <div class="btn btn-sm" :class="isUpdating ? 'btn-disabled' : 'btn-error'"
                   @click="commentAction(comment.id, 1)"><i class="bx bx-trash"> </i></div>

            </div>
          </div>
        </div>
      </div>
      <div v-if="comments.total == 0">
        <div class="card p-2 shadow-lg compact text-center bg-base-100 mx-1 mt-3 mb-2">
          <h2 class="font-bold text-lg">No Comments Found</h2>
        </div>
      </div>
      <div class="flex justify-center mt-2">
        <pagination class="menu menu-horizontal bg-base-100 rounded-box p-0" :data="comments" :limit="3"
                    @pagination-change-page="getComments"></pagination>
      </div>
    </div>

    <div class="mt-3" v-if="formType === 2">
      <div v-for="(trx, index) in transactions.data" :key="index">
        <div class="card p-2 shadow-lg compact text-center bg-base-100 mx-1 mb-2">
          <div class="flex-row items-center space-x-2.5 card mx-2 md:mx-4 px-1 pb-1">

            <div class="flex-1">
              <div class="flex-row items-center space-x-3 card mt-1 mb-2">
                <div class="avatar">
                  <div
                      class="rounded-full w-10 h-10 shadow"
                  >
                    <img v-if="trx.user.image" :src="url + '/' + trx.user.image"/>
                    <img v-else :src="url + '/user/no-image.png'"/>
                  </div>
                </div>
                <div class="flex flex-col space-y-1 items-start">

                  <div class="flex flex-row items-center">
                    <div class="card-title text-sm clickable" @click="viewUser(trx.user.username)">
                      {{ trx.user.name }}
                    </div>
                    <div v-if="trx.user.verified" class="ml-1">
                      <verification-svg height="13px" width="13px"/>
                    </div>
                  </div>

                  <div class="font-bold text-left">
                    <h1 v-if="trx.details && trx.details.length < 80" class="text-lg">
                      {{ trx.details }}
                    </h1>
                    <h1 v-if="trx.details && trx.details.length > 80" class="text-lg">
                      {{ trx.details.substring(0, 80) + "..." }}
                    </h1>
                  </div>

                </div>
              </div>
            </div>

            <div class="flex-0">
              <div class="flex flex-col space-y-1.5 items-end">
                <div class="flex-row items-center space-x-2 card mt-1 mb-2">
                  <div class="flex flex-row space-x-1 items-center">
                    <coin-svg height="17px" width="17px"/>
                    <div class="text-base font-semibold">
                      {{ trx.points }}
                    </div>
                  </div>
                </div>
                <div class="badge badge-accent">{{ trx.created_at | fromNow }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="transactions.total == 0">
        <div class="card p-2 shadow-lg compact text-center bg-base-100 mx-1 mt-3 mb-2">
          <h2 class="font-bold text-lg">No Transaction</h2>
        </div>
      </div>
      <div class="flex justify-center mt-2">
        <pagination class="menu menu-horizontal bg-base-100 rounded-box p-0" :data="transactions" :limit="3"
                    @pagination-change-page="getTrx"></pagination>
      </div>
    </div>


  </main>
</template>

<script>
import axios from "axios";


export default {
  mounted() {
    this.getStatusInfo();
    this.getComments();
    this.getTrx();
    this.$Progress.finish();
  },
  data() {
    return {
      url: this.$config.FILE_URL,
      status: {
        code: null,
        allow_comments: 0,
        title: null,
        description: null,
        media: {
          id: null,
          url: null,
          ext: null,
        },
      },
      modalInfo: {},
      categories: [],
      languages: [],
      comments: {},
      transactions: {},
      formType: 1,
      errors: {},
      infoLoading: false,
      commentsLoading: false,
      trxLoading: false,
      isUpdating: false,
      fullImage: false,

    };
  },
  methods: {
    getStatusInfo() {
      this.infoLoading = true;
      let code = this.$route.params.code;
      axios
          .get("/admin/status-details", {params: {code: code}})
          .then(({data}) => {
            this.status = data.status;
            this.languages = data.languages;
            this.categories = data.categories;
            this.infoLoading = false;
          })
          .catch((error) => {
            if (error.response.status === 404) {
              this.$router.replace({name: "notFound"});
            }
            this.infoLoading = false;
          });
    },
    getComments(page = 1) {
      this.commentsLoading = true;
      let code = this.$route.params.code;
      axios
          .get("/admin/status-comments", {params: {code: code, page: page}})
          .then(({data}) => {
            this.comments = data;
            this.commentsLoading = false;
          })
          .catch((error) => {
            if (error.response.status === 404) {
              this.$router.replace({name: "notFound"});
            } else {
              this.$noti('error', 'Something went wrong');
              this.commentsLoading = false;
            }
          });
    },
    getTrx(page = 1) {
      this.trxLoading = true;
      let code = this.$route.params.code;
      axios
          .get("/admin/status-transactions", {params: {code: code, page: page}})
          .then(({data}) => {
            this.transactions = data;
            this.trxLoading = false;
          })
          .catch((error) => {
            if (error.response.status === 404) {
              this.$router.replace({name: "notFound"});
            } else {
              this.$noti('error', 'Something went wrong');
              this.trxLoading = false;
            }
          });
    },
    commentAction(id, type) {
      this.isUpdating = true;
      axios.post("/admin/comment-action", {id: id, type: type})
          .then(({data}) => {
            this.isUpdating = false;
            this.getComments();
            this.$noti('success', data.message);
          })
          .catch((error) => {
            this.isUpdating = false;
            if (error.response.status === 404 || error.response.status === 406) {
              this.$noti('error', error.response.data.message);
            } else {
              this.$noti('error', 'Something went wrong');
            }
          });
    },
    tabClick(id) {
      if (this.formType == 1 && id == 1) {
        this.getComments();
      } else if (this.formType == 2 && id == 2) {
        this.getTrx();
      }
      this.formType = id;
    },

    viewUser(username) {
      this.$router.push({
        name: "user-details",
        params: {username: username},
      });
    },

    toggleHeight() {
      this.fullImage = !this.fullImage;
    },
  },
};
</script>

<style scoped></style>