<template>
  <main class="justify-center mx-auto">
    <div class="w-full shadow-2xl">
      <div class="p-2 bg-base-100 mx-1">
        <div class="flex flex-col">
          <div class="flex flex-row justify-between p-2 pb-0">
            <div class="card-title">Deposits</div>
            <div class="btn btn-accent btn-xs" @click="getDeposits">Refresh</div>
          </div>
          <div class="divider mt-0">Filter</div>
          <div class="flex flex-row justify-around px-2">
            <div class="flex flex-col md:flex-row items-center space-y-2 md:space-x-5">
              <div class="font-bold">Status :</div>
              <div>
                <select class="select select-accent w-full max-w-xs" v-model="filter.status" @change="getDeposits">
                  <option value="3" selected>All</option>
                  <option value="0">Pending</option>
                  <option value="1">Successful</option>
                  <option value="2">Rejected</option>
                </select>
              </div>
            </div>
            <div class="flex flex-col md:flex-row items-center space-y-2 md:space-x-5">
              <div class="font-bold">SortBy :</div>
              <div>
                <select class="select select-accent w-full max-w-xs" v-model="filter.sortBy" @change="getDeposits">
                  <option value="desc" selected>Latest</option>
                  <option value="asc">Oldest</option>
                </select>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="card-body p-1 pb-6">
        <div v-if="isLoading" class="mt-5">
          <content-placeholders :centered="true" :rounded="true">
            <content-placeholders-heading/>
            <content-placeholders-heading/>
            <content-placeholders-heading/>
            <content-placeholders-heading/>
            <content-placeholders-heading/>
          </content-placeholders>
        </div>
        <div v-else>
          <div v-if="deposits.total > 0">
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-3">
              <div v-for="(deposit, index) in deposits.data" :key="index">
                <div
                    class="card p-2 shadow-lg compact bg-base-100 mx-1 mt-3 mb-2"
                >
                  <div class="flex flex-col justify-around space-y-2">
                    <div class="avatar self-center mb-2">
                      <div class="w-36 rounded">
                        <img
                            v-if="deposit.method.logo"
                            :src="url + '/' + deposit.method.logo"
                        />
                        <img v-else :src="url + '/user/no-image.png'"/>
                      </div>
                    </div>

                    <div class="flex flex-row justify-between mx-1">
                      <div class="font-bold">User :</div>
                      <div class="clickable" @click="viewUser(deposit.user.username)">{{ deposit.user.name }}</div>
                    </div>

                    <div class="flex flex-row justify-between mx-1">
                      <div class="font-bold">Method :</div>
                      <div>{{ deposit.method.name }}</div>
                    </div>

                    <div class="flex flex-row justify-between mx-1">
                      <div class="font-bold">Status :</div>
                      <div>
                        <div
                            v-if="deposit.status === 0"
                            class="badge badge-warning"
                        >
                          Pending
                        </div>
                        <div
                            v-if="deposit.status === 1"
                            class="badge badge-success"
                        >
                          Successful
                        </div>
                        <div
                            v-if="deposit.status === 2"
                            class="badge badge-error"
                        >
                          Rejected
                        </div>
                      </div>
                    </div>

                    <div class="flex flex-row justify-between mx-1">
                      <div class="font-bold">Amount :</div>
                      <div class="flex flex-row space-x-1 items-center">
                        <div class="text-base font-semibold">
                          {{ currency.symbol }}
                        </div>
                        <div class="text-base font-semibold">
                          {{ deposit.amount }}
                        </div>
                      </div>
                    </div>

                    <div class="flex flex-row justify-between mx-1">
                      <div class="font-bold">Date :</div>
                      <div class="badge badge-accent">
                        {{ deposit.date | fromNow }}
                      </div>
                    </div>
                  </div>

                  <div
                      class="flex flex-row justify-center space-x-3 p-2 rounded pt-3 mt-1"
                  >
                    <div
                        class="btn btn-primary btn-sm"
                        @click="clickAction(deposit)"
                    >
                      <i class="bx bx-info-circle"></i>
                    </div>

                    <div class="btn btn-success btn-sm" v-if="deposit.status == 0"
                         @click="confirmButtonClick(deposit, 1)">
                      <i class="bx bx-check"></i>
                    </div>
                    <div class="btn btn-error btn-sm" v-if="deposit.status == 0"
                         @click="confirmButtonClick(deposit, 2)">
                      <i class="bx bx-x"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div
                class="card p-2 shadow-lg compact text-center bg-base-100 mx-1 mt-3 mb-2"
            >
              <h2 class="font-bold text-lg">Nothing to Show</h2>
            </div>
          </div>
        </div>
        <div class="flex justify-center mt-2">
          <pagination class="menu menu-horizontal bg-base-100 rounded-box p-0" :data="deposits" :limit="3"
                      @pagination-change-page="getDeposits"></pagination>
        </div>
      </div>
    </div>

    <modal
        :showing="showModal"
        :if-close="true"
        @update:modal="showModal = $event"
    >
      <div
          v-if="showModal"
          class="card p-2 shadow-lg compact bg-base-100 mx-1 mt-1"
      >
        <div class="divider">Deposit Details</div>

        <div class="form-control font-bold mt-3">
          <div class="flex flex-row justify-between items-center">
            <div>User :</div>
            <div class="flex flex-row space-x-1 items-center">
              <div>{{ methodInfo.user.name }}</div>
            </div>
          </div>
        </div>

        <div class="form-control font-bold mt-2">
          <div class="flex flex-row justify-between items-center">
            <div>Method :</div>
            <div class="flex flex-row space-x-1 items-center">
              <div>{{ methodInfo.method.name }}</div>
            </div>
          </div>
        </div>

        <div class="form-control font-bold mt-2">
          <div class="flex flex-row justify-between items-center">
            <div>Amount :</div>
            <div class="flex flex-row space-x-1 items-center">
              <div>{{ currency.symbol }}</div>
              <div>{{ methodInfo.amount }}</div>
            </div>
          </div>
        </div>

        <div class="form-control font-bold mt-2">
          <div class="flex flex-row justify-between items-center">
            <div>Status :</div>
            <div class="flex flex-row space-x-1 items-center">
              <div v-if="methodInfo.status === 0" class="badge badge-warning">
                Pending
              </div>
              <div v-if="methodInfo.status === 1" class="badge badge-success">
                Successful
              </div>
              <div v-if="methodInfo.status === 2" class="badge badge-error">
                Rejected
              </div>
            </div>
          </div>
        </div>

        <div class="form-control font-bold mt-2">
          <div class="flex flex-row justify-between items-center">
            <div>Date :</div>
            <div class="flex flex-row space-x-1 items-center">
              <div class="text-sm">
                {{ methodInfo.date | timeDate }}
              </div>
            </div>
          </div>
        </div>

        <div
            v-if="methodInfo.status === 2 && methodInfo.reject_note"
            class="form-control font-bold mt-2"
        >
          <div class="flex flex-col items-start">
            <div>Reject Note :</div>
            <div class="flex flex-row space-x-1 items-center">
              <p class="text-sm font-thin ml-2 text-error">
                {{ methodInfo.reject_note }}
              </p>
            </div>
          </div>
        </div>

        <div class="form-control font-bold mt-2 mb-3">
          <div class="flex flex-col items-start">
            <div>Payment Details :</div>
            <div class="flex flex-row space-x-1 items-center">
              <p class="text-sm font-thin ml-2">{{ methodInfo.note }}</p>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
        :if-close="true"
        :showing="confirmModal"
        @update:modal="confirmModal = $event"
    >
      <div
          v-if="confirmModal"
          class="card p-2 shadow-lg compact bg-base-100 mx-1 mt-1"
      >
        <div class="divider">Are You Sure ?</div>
        <div class="text-xl mt-5" v-if="modalInfo.type === 1">
          Approve : {{ modalInfo.method }} from {{ modalInfo.name }} !<span> </span>
        </div>
        <div class="text-xl mt-5" v-if="modalInfo.type === 2">
          Reject : {{ modalInfo.method }} from {{ modalInfo.name }} !<span> </span>
        </div>
        <div class="form-control mt-2" v-if="modalInfo.type === 2">
          <label class="label">
            <span class="label-text font-bold">Reject Note : </span>
          </label>
          <textarea v-model="reject_note" class="textarea textarea-accent"
                    placeholder="Ex: You didn't follow instructions"/>
        </div>
        <div class="form-control mt-8 mb-3">
          <div class="flex flex-row space-x-5 justify-center">
            <button :disabled="isUpdating" class="btn btn-outline" @click="confirmModal = false">
              Cancel
            </button>
            <button :disabled="isUpdating" class="btn btn-success" v-if="modalInfo.type === 1"
                    @click="approveDeposit(modalInfo.id)">
              <svg-loading v-if="isUpdating" class="mr-1" height="16px" width="16px"/>
              Approve
            </button>
            <button :disabled="isUpdating" class="btn btn-error" v-if="modalInfo.type === 2"
                    @click="rejectDeposit(modalInfo.id)">
              <svg-loading v-if="isUpdating" class="mr-1" height="16px" width="16px"/>
              Reject
            </button>
          </div>
        </div>
      </div>
    </modal>
  </main>
</template>

<script>
import axios from "axios";

export default {
  beforeCreate() {
    document.title = this.$route.meta.title + " | " + this.$sitename;
  },
  created() {
    this.getDeposits();
  },
  mounted() {
    this.$Progress.finish();
  },
  data() {
    return {
      url: this.$config.FILE_URL,
      deposits: {},
      currency: {
        symbol: null,
      },
      methodInfo: {},
      modalInfo: {
        id: null,
        name: null,
        method: null,
        amount: null,
        type: 0,
      },
      reject_note: null,
      filter: {
        status: '3',
        sortBy: 'desc',
      },
      isLoading: false,
      isUpdating: false,
      errors: {},
      showModal: false,
      confirmModal: false,
    };
  },
  methods: {
    getDeposits(page = 1) {
      this.isLoading = true;
      let status = this.filter.status;
      if (status !== '') {
        status = parseInt(status)
        if (status === 3) {
          status = ''
        }
      }
      let sortBy = this.filter.sortBy;
      let urlData = {params: {status: status, sortBy: sortBy, page: page}};
      if (this.$route.name == 'user-details') {
        let u = this.$route.params.username;
        urlData = {params: {username: u, status: status, sortBy: sortBy, page: page}};
      }
      axios.get('/admin/deposits', urlData)
          .then(({data}) => {
            this.deposits = data.data;
            this.currency = data.currency;
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            this.errors = error.response.data.errors;
          });
    },
    clickAction(data) {
      try {
        this.methodInfo = data;
      } finally {
        this.showModal = true;
      }
    },
    approveDeposit(id) {
      this.isUpdating = true;
      axios.post('/admin/approve-deposit', {id: id})
          .then(() => {
            this.isUpdating = false;
            this.getDeposits();
            this.$noti('success', 'Approved Successfully');
            this.confirmModal = false;
          }).catch(() => {
        this.isUpdating = false;
        this.$noti('error', 'Something went wrong')
      })
    },
    rejectDeposit(id) {
      this.isUpdating = true;
      axios.post('/admin/reject-deposit', {id: id, note: this.reject_note})
          .then(() => {
            this.isUpdating = false;
            this.getDeposits();
            this.$noti('warning', 'Rejected Successfully');
            this.confirmModal = false;
          }).catch(() => {
        this.isUpdating = false;
        this.$noti('error', 'Something went wrong')
      })

    },
    confirmButtonClick(data, type) {
      try {
        this.modalInfo = {
          id: data.id,
          name: data.user.name,
          method: data.method.name,
          amount: data.amount,
          type: type
        }
      } finally {
        this.confirmModal = true;
      }
    },
    viewUser(username) {
      this.$router.push({
        name: "user-details",
        params: {username: username},
      });
    },

  },
};
</script>

<style scoped></style>