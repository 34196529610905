<template>
  <main class="justify-center mx-auto">
    <div class="w-full shadow-2xl">
      <div class="p-2 bg-base-100 mx-1">
        <div class="flex flex-col">
          <div class="flex flex-row justify-between p-2 pb-0">
            <div class="card-title">Support Tickets</div>
            <div class="btn btn-accent btn-xs" @click="getTickets">Refresh</div>
          </div>
          <div class="divider mt-0">Filter</div>
          <div class="flex flex-row justify-around px-2">
            <div class="flex flex-col md:flex-row items-center space-y-2 md:space-x-5">
              <div class="font-bold">Status :</div>
              <div>
                <select class="select select-accent w-full max-w-xs" v-model="filter.status" @change="getTickets">
                  <option value="3" selected>All</option>
                  <option value="1">Active</option>
                  <option value="0">Closed</option>
                </select>
              </div>
            </div>
            <div class="flex flex-col md:flex-row items-center space-y-2 md:space-x-5">
              <div class="font-bold">SortBy :</div>
              <div>
                <select class="select select-accent w-full max-w-xs" v-model="filter.sortBy" @change="getTickets">
                  <option value="desc" selected>Latest</option>
                  <option value="asc">Oldest</option>
                </select>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="card-body p-1 pb-6">
        <div v-if="isLoading" class="mt-5">
          <content-placeholders :centered="true" :rounded="true">
            <content-placeholders-heading/>
            <content-placeholders-heading/>
            <content-placeholders-heading/>
            <content-placeholders-heading/>
            <content-placeholders-heading/>
          </content-placeholders>
        </div>
        <div v-else>
          <div v-if="tickets.data">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-3">
              <div v-for="(ticket, index) in tickets.data" :key="index">
                <div
                    class="card p-2 shadow-lg compact bg-base-100 mx-1 mt-3 mb-2"
                >
                  <div class="flex flex-col justify-around space-y-2">
                    <div class="self-center text-lg font-bold my-2">
                      <h1 v-if="ticket.title && ticket.title.length < 80">
                        {{ ticket.title }}
                      </h1>
                      <h1 v-if="ticket.title && ticket.title.length > 80">
                        {{ ticket.title.substring(0, 80) + "..." }}
                      </h1>
                    </div>

                    <div class="flex flex-row justify-between mx-1">
                      <div class="font-bold">User :</div>
                      <div class="clickable" @click="viewUser(ticket.user.username)">{{ ticket.user.name }}</div>
                    </div>

                    <div class="flex flex-row justify-between mx-1">
                      <div class="font-bold">Active :</div>
                      <div>
                        <div v-if="ticket.active === 1" class="badge badge-info">Yes</div>
                        <div v-if="ticket.active === 0" class="badge badge-warning">Closed</div>
                      </div>
                    </div>

                    <div class="flex flex-row justify-between mx-1">
                      <div class="font-bold">Status :</div>
                      <div>
                        <div v-if="parseInt(ticket.user_status) === 0">Not Seen Yet</div>
                        <div v-if="parseInt(ticket.user_status) === 1">Message Seen</div>
                        <div v-if="parseInt(ticket.user_status) === 2">User Replied</div>
                      </div>
                    </div>


                    <div class="flex flex-row justify-between mx-1">
                      <div class="font-bold">Date :</div>
                      <div class="badge badge-accent">
                        {{ ticket.date | fromNow }}
                      </div>
                    </div>
                  </div>

                  <div class="self-center p-2 rounded pt-3 mt-1">
                    <router-link :to="{name: 'ticket-details', params:{code: ticket.code}}"
                                 class="btn btn-primary space-x-2">
                      <i class="bx bx-info-circle"></i>
                      <div>View Details</div>
                    </router-link>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div
                class="card p-2 shadow-lg compact text-center bg-base-100 mx-1 mt-3 mb-2"
            >
              <h2 class="font-bold text-lg">Nothing to Show</h2>
            </div>
          </div>
        </div>
        <div class="flex justify-center mt-2">
          <pagination class="menu menu-horizontal bg-base-100 rounded-box p-0" :data="tickets" :limit="3"
                      @pagination-change-page="getTickets"></pagination>
        </div>
      </div>
    </div>

  </main>
</template>

<script>
import axios from "axios";

export default {
  beforeCreate() {
    document.title = this.$route.meta.title + " | " + this.$sitename;
  },
  created() {
    this.getTickets();
  },
  mounted() {
    this.$Progress.finish();
  },
  data() {
    return {
      url: this.$config.FILE_URL,
      tickets: {},
      filter: {
        status: '3',
        sortBy: 'desc',
      },
      isLoading: false,
      errors: {},
    };
  },
  methods: {
    getTickets(page = 1) {
      this.isLoading = true;
      let status = this.filter.status;
      if (status !== '') {
        status = parseInt(status)
        if (status === 3) {
          status = ''
        }
      }
      let sortBy = this.filter.sortBy;
      axios.get('/admin/support-tickets', {params: {status: status, sortBy: sortBy, page: page}})
          .then(({data}) => {
            this.tickets = data;
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            this.errors = error.response.data.errors;
          });
    },

    viewUser(username) {
      this.$router.push({
        name: "user-details",
        params: {username: username},
      });
    },

  },
};
</script>

<style scoped></style>