<template>
  <main class="justify-center mx-auto">
    <div class="w-full shadow-2xl">
      <div class="p-2 bg-base-100 mx-1">
        <div class="flex flex-col">
          <div class="flex flex-row justify-between p-2 pb-0">
            <div class="card-title">Transactions</div>
            <div class="btn btn-accent btn-xs" @click="getData">Refresh</div>
          </div>

        </div>
      </div>
      <div class="card-body p-1 pb-6 mt-2">
        <div v-if="isLoading" class="mt-5">
          <content-placeholders :centered="true" :rounded="true">
            <content-placeholders-heading/>
            <content-placeholders-heading/>
            <content-placeholders-heading/>
            <content-placeholders-heading/>
            <content-placeholders-heading/>
          </content-placeholders>
        </div>
        <div v-else>
          <div v-if="data.data">
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-2 gap-y-1">
              <div v-for="(trx, index) in data.data" :key="index">
                <div
                    class="card p-2 shadow-lg compact text-center bg-base-100 mx-1 mb-2"
                    :class="trx.status ? 'clickable' : ''"
                    @click="trxClickAction(trx)"
                >
                  <div
                      class="flex-row items-center space-x-2.5 card mx-2 md:mx-4 px-1 pb-1"
                  >

                    <div class="flex-1">
                      <div class="flex-row items-center space-x-3 card mt-1 mb-2">
                        <div class="avatar">
                          <div
                              class="rounded-full w-10 h-10 shadow"
                          >
                            <img v-if="trx.status && trx.status.thumb" :src="url + '/' + trx.status.thumb"/>
                            <img v-else :src="url + '/user/no-image.png'"/>
                          </div>
                        </div>
                        <div class="flex flex-col space-y-1.5 items-start">
                          <div class="font-bold text-left">
                            <h1
                                v-if="trx.details && trx.details.length < 80"
                                class="text-lg"
                            >
                              {{ trx.details }}
                            </h1>
                            <h1
                                v-if="trx.details && trx.details.length > 80"
                                class="text-lg"
                            >
                              {{ trx.details.substring(0, 80) + "..." }}
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="flex-0">
                      <div class="flex flex-col space-y-1.5 items-end">
                        <div class="flex-row items-center space-x-2 card mt-1 mb-2">
                          <div class="flex flex-row space-x-1 items-center">
                            <coin-svg height="17px" width="17px"/>
                            <div class="text-base font-semibold">
                              {{ trx.points }}
                            </div>
                          </div>
                        </div>
                        <div class="badge badge-accent">{{ trx.created_at | fromNow }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="data.total == 0">
            <div
                class="card p-2 shadow-lg compact text-center bg-base-100 mx-1 mt-3 mb-2"
            >
              <h2 class="font-bold text-lg">Nothing to Show</h2>
            </div>
          </div>
        </div>
        <div class="flex justify-center mt-2">
          <pagination class="menu menu-horizontal bg-base-100 rounded-box p-0" :data="data" :limit="3"
                      @pagination-change-page="getData"></pagination>
        </div>
      </div>
    </div>

  </main>
</template>

<script>
import axios from "axios";

export default {
  beforeCreate() {
    document.title = this.$route.meta.title + " | " + this.$sitename;
  },
  mounted() {
    this.getData();
    this.$Progress.finish();
  },

  data() {
    return {
      url: this.$config.FILE_URL,
      data: {
        total: null,
      },
      isLoading: false,
      errors: {},
    };
  },
  watch: {
    type() {
      this.getData();
    }
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true;
      let username = this.$route.params.username;
      try {
        let r = await axios.get('/admin/user-transactions', {params: {username: username, page: page}});
        this.data = r.data;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.$noti('error', 'Something went wrong');
      }
    },
    trxClickAction(trx) {
      if (trx.status_id && trx.status.code) {
        this.$router.push({name: 'status-details', params: {code: trx.status.code}});
      }
    },

  },
};
</script>

<style scoped></style>