import Vue from 'vue';
import Vuex from "vuex";
import axios from "axios";
import UserStore from "./users";
import {authCheck} from "./auth"

Vue.use(Vuex)
export default new Vuex.Store({
    modules: {
        UserStore,
    },
    state: {
        token: null,
        isLoggedIn: false,
        last_route: null,
        siteInfo: {},
        statuses: {
            current_page: null,
            data: [],
            total: 0,
        },
        reviewStatuses: {
            current_page: null,
            data: [],
            total: 0,
        },
        reports: {
            current_page: null,
            data: [],
            total: 0,
        },
    },
    getters: {
        isLoggedIn(state) {
            return state.isLoggedIn
        },
        lastRoute(state) {
            return state.last_route
        },
        siteInfo(state) {
            return state.siteInfo
        },
        statuses(state) {
            return state.statuses
        },
        reviewStatuses(state) {
            return state.reviewStatuses
        },
        reports(state) {
            return state.reports
        },
    },
    mutations: {
        setToken(state, token) {
            state.token = token
        },
        setLoggedIn(state, status) {
            state.isLoggedIn = status
        },
        setLastRoute(state, name) {
            state.last_route = name
        },
        setSiteInfo(state, info) {
            state.siteInfo = info
        },
        setStatuses(state, data) {
            state.statuses = data
        },
        setReviewStatuses(state, data) {
            state.reviewStatuses = data
        },
        setReports(state, data) {
            state.reports = data
        },

    },
    actions: {

        lastRouteAction({commit}, name) {
            commit('setLastRoute', name);
        },

        async getStatuses({commit}, urlData) {
            try {
                let response = await axios.get('/admin/statuses', urlData);
                commit('setStatuses', response.data)
            } catch (e) {
            }
        },

        async getReviewStatuses({commit}, urlData) {
            try {
                let response = await axios.get('/admin/pending-statuses', urlData);
                commit('setReviewStatuses', response.data)
            } catch (e) {
            }
        },

        async getReports({commit}, urlData) {
            try {
                let response = await axios.get('/admin/reports', urlData);
                commit('setReports', response.data)
            } catch (e) {
            }
        },

        async siteInfo({commit}) {
            try {
                let response = await axios.get('/site-info')
                commit('setSiteInfo', response.data)
            } catch (e) {
            }
        },

        async signIn({dispatch}, credentials) {
            let response = await axios.post('/admin/login', credentials)
            return dispatch('attempt', response.data.token)
        },

        async attempt({commit, state, dispatch}, token) {
            if (token) {
                commit('setToken', token)
            }
            if (!state.token) {
                return
            }
            try {
                let response = await axios.get('/admin/verify')
                localStorage.setItem('isLoggedIn', 'true')
                commit('setLoggedIn', true)
            } catch (e) {
            }
        },

        setAuth({commit}) {
            commit('setLoggedIn', authCheck())
        },
        signOut({commit}) {
            return axios.post('/logout').then(() => {
                localStorage.removeItem('isLoggedIn')
                commit('setLoggedIn', false)
                commit('setToken', null)
            })
        },
        clearData({commit}) {
            localStorage.removeItem('isLoggedIn');
            commit('setToken', null)
            commit('setLoggedIn', false)
        },

    },


})

