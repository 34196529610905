<template>
  <main class="justify-center mx-auto">
    <div class="mx-2 mt-3">
      <div class="flex flex-row w-full justify-between">
        <div
            :class="formType === 1 ? 'tab-active' : ''"
            class="flex-grow tab tab-bordered"
            @click="tabClick(1)"
        >
          Slides
        </div>
        <div
            :class="formType === 2 ? 'tab-active' : ''"
            class="flex-grow tab tab-bordered"
            @click="tabClick(2)"
        >
          New Slide
        </div>
      </div>
    </div>

    <div v-if="formType === 1">
      <div class="w-full shadow-2xl">
        <div class="card-body p-1 pb-6">
          <div v-if="isLoading" class="mt-5">
            <content-placeholders :centered="true" :rounded="true">
              <content-placeholders-heading/>
              <content-placeholders-heading/>
              <content-placeholders-heading/>
              <content-placeholders-heading/>
              <content-placeholders-heading/>
            </content-placeholders>
          </div>
          <div v-else>
            <div v-if="slides.length" class="mt-5">
              <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-3">
                <div v-for="(slide, index) in slides" :key="index">
                  <div
                      class="card p-2 shadow-lg compact bg-base-100 mx-1 mt-3 mb-2"
                  >

                    <div class="flex flex-col justify-around space-y-2">

                      <div class="avatar self-center">
                        <div class="w-56 rounded">
                          <img
                              :alt="slide.name"
                              :src="url + slide.image"
                          />
                        </div>
                      </div>

                      <div class="flex flex-row justify-between mx-1">
                        <div class="font-bold">Title :</div>
                        <div>
                          <h1 v-if="slide.title && slide.title.length < 80">
                            {{ slide.title }}
                          </h1>
                          <h1 v-if="slide.title && slide.title.length > 80">
                            {{ slide.title.substring(0, 80) + "..." }}
                          </h1>
                        </div>
                      </div>


                      <div class="flex flex-row justify-between mx-1">
                        <div class="font-bold">Type :</div>
                        <div>
                          <span v-if="slide.type === 1" class="badge badge-info"> Status </span>
                          <span v-if="slide.type === 2" class="badge badge-info"> Category </span>
                          <span v-if="slide.type === 3" class="badge badge-info"> Custom </span>
                        </div>
                      </div>

                      <div class="flex flex-row justify-between mx-1">
                        <div class="font-bold">URL :</div>
                        <div>
                          <span v-if="slide.url">{{ slide.url }}</span>
                          <span v-else>N/A</span>
                        </div>
                      </div>


                    </div>

                    <div
                        class="flex flex-row justify-center space-x-3 p-2 rounded pt-4"
                    >
                      <div
                          class="btn btn-primary btn-sm"
                          @click="editClickAction(slide)"
                      >
                        <i class="bx bx-edit"></i>
                      </div>
                      <div
                          class="btn btn-primary btn-sm"
                          @click="deleteButtonClick(slide.id, slide.title)"
                      >
                        <i class="bx bx-trash"></i>
                      </div>
                      <div
                          v-if="index !== 0"
                          class="btn btn-primary btn-sm"
                          @click="changePosition(slide.id, 'up')"
                      >
                        <i class="bx bx-up-arrow"></i>
                      </div>
                      <div
                          v-if="index !== slides.length - 1"
                          class="btn btn-primary btn-sm"
                          @click="changePosition(slide.id, 'down')"
                      >
                        <i class="bx bx-down-arrow"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div
                  class="card p-2 shadow-lg compact text-center bg-base-100 mx-1 mt-3 mb-2"
              >
                <h2 class="font-bold text-lg">Nothing to Show</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="formType === 2">
      <div class="pt-4 w-full justify-center mx-auto md:w-5/6 shadow-2xl">
        <div class="card-body pt-0">
          <form @submit.prevent="createSlide">
            <div class="form-control mt-5">
              <label class="label">
                <span class="label-text font-bold">Slide Type : </span>
              </label>
              <select
                  v-model="form.type"
                  class="select select-accent"
                  @change="typeAction"
              >
                <option disabled="disabled" selected="selected" value="">
                  Choose Type
                </option>
                <option value="1">Status</option>
                <option value="2">Category</option>
                <option value="3">Custom</option>
              </select>
              <small v-if="errors.type" class="text-red-600">{{
                  errors.type[0]
                }}</small>
            </div>

            <div v-if="form.type == 1" class="form-control">
              <label class="label">
                <span class="label-text font-bold"
                >Status Selection Type :
                </span>
              </label>
              <select
                  v-model="form.status_type"
                  class="select select-accent"
                  @change="statusTypeChange"
              >
                <option disabled="disabled" selected="selected" value="">
                  Choose Option to Choose From
                </option>
                <option value="1">Status Code</option>
                <option value="2">Status List</option>
              </select>
              <small v-if="errors.status_type" class="text-red-600">{{
                  errors.status_type[0]
                }}</small>
            </div>

            <div
                v-if="form.type == 1 && form.status_type == 1"
                class="form-control"
            >
              <label class="label">
                <span class="label-text font-bold">Status Code : </span>
              </label>
              <input
                  v-model="form.status_code"
                  class="input input-accent"
                  placeholder="Status Code"
                  type="text"
              />
              <small v-if="errors.status_code" class="text-red-600">{{
                  errors.status_code[0]
                }}</small>
            </div>

            <div
                v-if="form.type == 1 && form.status_type == 2"
                class="form-control"
            >
              <label class="label">
                <span class="label-text font-bold">Status : </span>
              </label>
              <select v-model="form.status_id" class="select select-accent">
                <option
                    v-if="statusLoading"
                    disabled="disabled"
                    selected="selected"
                    value=""
                >
                  Status Loading...
                </option>
                <option v-else disabled="disabled" selected="selected" value="">
                  Choose Status
                </option>
                <option v-for="status in statuses" :value="status.id">
                  {{ status.id + ") " + status.title.substring(0, 50) }}
                </option>
              </select>
              <small v-if="errors.status_id" class="text-red-600">{{
                  errors.status_id[0]
                }}</small>
            </div>

            <div v-if="form.type == 2" class="form-control">
              <label class="label">
                <span class="label-text font-bold">Category : </span>
              </label>
              <select v-model="form.category_id" class="select select-accent">
                <option
                    v-if="categoryLoading"
                    disabled="disabled"
                    selected="selected"
                    value=""
                >
                  Category Loading...
                </option>
                <option v-else disabled="disabled" selected="selected" value="">
                  Choose Category
                </option>
                <option v-for="category in categories" :value="category.id">
                  {{ category.name.substring(0, 50) }}
                </option>
              </select>
              <small v-if="errors.category_id" class="text-red-600">{{
                  errors.category_id[0]
                }}</small>
            </div>

            <div class="form-control">
              <label class="label">
                <span class="label-text font-bold">Title : </span>
              </label>
              <input
                  v-model="form.title"
                  class="input input-accent"
                  placeholder="Slide Title"
                  type="text"
              />
              <small v-if="errors.title" class="text-red-600">{{
                  errors.title[0]
                }}</small>
            </div>

            <div class="form-control mt-3">
              <label class="label">
                <span class="label-text font-bold">Image : </span>
              </label>
              <input
                  ref="createImageInput"
                  accept=".jpg,.png,.jpeg,.heic"
                  class="file"
                  type="file"
                  @change="imageChange"
              />
              <small v-if="errors.image" class="text-red-600">{{
                  errors.image[0]
                }}</small>
            </div>
            <div v-if="form.image" class="form-control mt-4">
              <div class="flex flex-wrap justify-center">
                <div class="w-8/12 sm:w-6/12 px-4">
                  <img
                      :src="form.image"
                      alt="Slide Image"
                      class="rounded max-w-full h-auto align-middle border-none"
                  />
                </div>
              </div>
            </div>

            <div v-if="form.type == 3" class="form-control mt-2">
              <label class="label">
                <span class="label-text font-bold"
                >Url <span class="text-xs">(optional)</span> :
                </span>
              </label>
              <input
                  v-model="form.url"
                  class="input input-accent"
                  placeholder="Action URL"
                  type="text"
              />
              <small v-if="errors.url" class="text-red-600">{{
                  errors.url[0]
                }}</small>
            </div>

            <div class="form-control mt-8">
              <button
                  :disabled="isCreating"
                  class="btn btn-primary"
                  type="submit"
              >
                <svg-loading
                    v-if="isCreating"
                    class="mr-1"
                    height="16px"
                    width="16px"
                />
                Create Slide
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <modal
        :if-close="true"
        :showing="editModal"
        @update:modal="editModal = $event"
    >
      <div
          v-if="editModal"
          class="card p-2 shadow-lg compact bg-base-100 mx-1 mt-1"
      >
        <div class="divider">Edit Details</div>

        <form @submit.prevent="updateSlide">
          <div class="form-control mt-5">
            <label class="label">
              <span class="label-text font-bold">Title : </span>
            </label>
            <input
                v-model="editForm.title"
                class="input input-accent"
                placeholder="Ex: Funny"
                type="text"
            />
            <small v-if="errors.title" class="text-red-600">{{
                errors.title[0]
              }}</small>
          </div>

          <div v-if="editForm.type == 3" class="form-control mt-2">
            <label class="label">
              <span class="label-text font-bold">URL : </span>
            </label>
            <input
                v-model="editForm.url"
                class="input input-accent"
                placeholder="Ex: funny-meme"
                type="text"
            />
            <small v-if="errors.url" class="text-red-600">{{
                errors.url[0]
              }}</small>
          </div>

          <div v-if="editForm.type == 1" class="form-control">
            <label class="label">
              <span class="label-text font-bold">Status Selection Type : </span>
            </label>
            <select
                v-model="editForm.status_type"
                class="select select-accent"
                @change="editStatusTypeChange"
            >
              <option disabled="disabled" selected="selected" value="">
                Choose Option to Choose From
              </option>
              <option value="1">Status Code</option>
              <option value="2">Status List</option>
            </select>
            <small v-if="errors.status_type" class="text-red-600">{{
                errors.status_type[0]
              }}</small>
          </div>

          <div
              v-if="editForm.type == 1 && editForm.status_type == 1"
              class="form-control"
          >
            <label class="label">
              <span class="label-text font-bold">Status Code : </span>
            </label>
            <input
                v-model="editForm.status_code"
                class="input input-accent"
                placeholder="Status Code"
                type="text"
            />
            <small v-if="errors.status_code" class="text-red-600">{{
                errors.status_code[0]
              }}</small>
          </div>

          <div
              v-if="editForm.type == 1 && editForm.status_type == 2"
              class="form-control"
          >
            <label class="label">
              <span class="label-text font-bold">Status : </span>
            </label>
            <select v-model="editForm.status_id" class="select select-accent">
              <option
                  v-if="statusLoading"
                  disabled="disabled"
                  selected="selected"
                  value=""
              >
                Status Loading...
              </option>
              <option v-else disabled="disabled" selected="selected" value="">
                Choose Status
              </option>
              <option v-for="status in statuses" :value="status.id">
                {{ status.id + ") " + status.title.substring(0, 50) }}
              </option>
            </select>
            <small v-if="errors.status_id" class="text-red-600">{{
                errors.status_id[0]
              }}</small>
          </div>

          <div v-if="editForm.type == 2" class="form-control">
            <label class="label">
              <span class="label-text font-bold">Category : </span>
            </label>
            <select v-model="editForm.category_id" class="select select-accent">
              <option
                  v-if="categoryLoading"
                  disabled="disabled"
                  selected="selected"
                  value=""
              >
                Category Loading...
              </option>
              <option v-else disabled="disabled" selected="selected" value="">
                Choose Category
              </option>
              <option v-for="category in categories" :value="category.id">
                {{ category.name.substring(0, 50) }}
              </option>
            </select>
            <small v-if="errors.category_id" class="text-red-600">{{
                errors.category_id[0]
              }}</small>
          </div>

          <div class="form-control mt-3">
            <label class="label">
              <span class="label-text font-bold">Image : </span>
            </label>
            <input
                accept=".jpg,.png,.jpeg,.heic"
                class="file"
                type="file"
                @change="editImageChange"
            />
            <small v-if="errors.image" class="text-red-600">{{
                errors.image[0]
              }}</small>
          </div>
          <div v-if="editForm.image" class="form-control mt-4">
            <div class="flex flex-wrap justify-center">
              <div class="w-8/12 sm:w-6/12 px-4">
                <img
                    :src="editForm.image"
                    alt="Category Image"
                    class="rounded max-w-full h-auto align-middle border-none"
                />
              </div>
            </div>
          </div>

          <div class="form-control mt-8 mb-3">
            <button
                :disabled="isUpdating"
                class="btn btn-primary"
                type="submit"
            >
              <svg-loading
                  v-if="isUpdating"
                  class="mr-1"
                  height="16px"
                  width="16px"
              />
              Update Slide
            </button>
          </div>
        </form>
      </div>
    </modal>

    <modal
        :if-close="true"
        :showing="deleteModal"
        @update:modal="deleteModal = $event"
    >
      <div
          v-if="deleteModal"
          class="card p-2 shadow-lg compact bg-base-100 mx-1 mt-1"
      >
        <div class="divider">Are You Sure ?</div>
        <div class="text-2xl mt-5">
          Delete : {{ deleteInfo.title }} !<span> </span>
        </div>
        <div class="form-control mt-8 mb-3">
          <div class="flex flex-row space-x-5 justify-center">
            <button
                :disabled="isDeleting"
                class="btn btn-outline"
                @click="deleteModal = false"
            >
              Cancel
            </button>
            <button
                :disabled="isDeleting"
                class="btn btn-error"
                @click="deleteSlide"
            >
              <svg-loading
                  v-if="isDeleting"
                  class="mr-1"
                  height="16px"
                  width="16px"
              />
              Delete
            </button>
          </div>
        </div>
      </div>
    </modal>
  </main>
</template>

<script>
import {mapActions} from "vuex";
import axios from "axios";

export default {
  beforeCreate() {
    document.title = this.$route.meta.title + " | " + this.$sitename;
  },
  created() {
    this.getSlides();
  },
  mounted() {
    this.$Progress.finish();
  },
  data() {
    return {
      url: this.$config.FILE_URL,
      formType: 1,
      slides: [],
      statuses: [],
      categories: [],
      form: {
        type: "",
        title: null,
        image: null,
        url: null,
        status_type: "",
        status_code: null,
        status_id: "",
        category_id: "",
      },
      editForm: {
        id: null,
        type: "",
        title: null,
        image: null,
        url: null,
        status_type: "",
        status_code: null,
        status_id: "",
        category_id: "",
      },
      deleteInfo: {
        id: null,
        title: null,
      },
      isLoading: false,
      statusLoading: false,
      categoryLoading: false,
      isCreating: false,
      isUpdating: false,
      isDeleting: false,
      errors: {},
      editModal: false,
      deleteModal: false,
    };
  },
  methods: {
    getSlides() {
      this.isLoading = true;
      axios
          .get("/admin/slides")
          .then(({data}) => {
            this.slides = data;
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            this.errors = error.response.data.errors;
          });
    },
    tabClick(id) {
      if (this.formType == 1 && id == 1) {
        this.getSlides();
      }
      this.formType = id;
    },

    editClickAction(data) {
      try {
        this.editForm = {
          id: data.id,
          type: data.type,
          title: data.title,
          url: data.url,
          status_type: "",
          status_code: "",
          status_id: data.status_id,
          category_id: "",
        };
        if (data.type == 2) {
          this.getCategories();
          this.editForm.category_id = data.category_id;
        }
        if (data.status && data.status.code) {
          this.editForm.status_code = data.status.code;
        }
      } finally {
        this.editModal = true;
      }
    },
    imageChange(event) {
      let file = event.target.files[0];
      let reader = new FileReader();
      reader.onload = (event) => {
        this.form.image = event.target.result;
      };
      reader.readAsDataURL(file);
    },
    editImageChange(event) {
      let file = event.target.files[0];
      let reader = new FileReader();
      reader.onload = (event) => {
        this.editForm.image = event.target.result;
      };
      reader.readAsDataURL(file);
    },
    createSlide() {
      this.isCreating = true;
      this.errors = {};
      axios
          .post("/admin/create-slide", this.form)
          .then(({data}) => {
            this.isCreating = false;
            this.resetForm();
            this.getSlides();
            this.$noti("success", data.message);
          })
          .catch((error) => {
            this.isCreating = false;
            if (error.response.status === 404 || error.response.status === 406) {
              this.$noti("warning", error.response.data.message);
            } else {
              this.errors = error.response.data.errors;
              this.$noti("error", "Something Went Wrong !");
            }
          });
    },
    deleteSlide() {
      this.isDeleting = true;
      this.errors = {};
      axios
          .post("/admin/delete-slide", {id: this.deleteInfo.id})
          .then(({data}) => {
            this.isDeleting = false;
            this.getSlides();
            this.deleteModal = false;
            this.deleteInfo = {};
            this.$noti("success", data.message);
          })
          .catch((error) => {
            this.isDeleting = false;
            if (error.response.status === 404 || error.response.status === 406) {
              this.$noti("warning", error.response.data.message);
            } else {
              this.errors = error.response.data.errors;
              this.$noti("error", "Something Went Wrong !");
            }
          });
    },
    deleteButtonClick(id, title) {
      this.deleteInfo = {id: id, title: title};
      this.deleteModal = true;
    },
    updateSlide() {
      this.isUpdating = true;
      this.errors = {};
      axios
          .patch("/admin/update-slide", this.editForm)
          .then(({data}) => {
            this.isUpdating = false;
            this.editForm = {
              id: null,
              type: "",
              title: null,
              image: null,
              url: null,
              status_type: "",
              status_code: null,
              status_id: "",
              category_id: "",
            };
            this.getSlides();
            this.editModal = false;
            this.$noti("success", data.message);
          })
          .catch((error) => {
            this.isUpdating = false;
            if (error.response.status === 406 || error.response.status === 404) {
              this.$noti("warning", error.response.data.message);
            } else {
              this.errors = error.response.data.errors;
              this.$noti("error", "Something Went Wrong !");
            }
          });
    },
    changePosition(id, type) {
      axios
          .patch("/admin/slide-position", {id: id, type: type})
          .then(({data}) => {
            this.getSlides();
            this.$noti("success", data.message);
          })
          .catch((error) => {
            if (error.response.status === 404) {
              this.$noti("warning", error.response.data.message);
            } else {
              this.errors = error.response.data.errors;
              this.$noti("error", "Something Went Wrong !");
            }
          });
    },
    resetForm() {
      this.form = {
        type: "",
        title: null,
        image: null,
        url: null,
        status_type: "",
        status_code: null,
        status_id: "",
        category_id: "",
      };
      this.$refs.createImageInput.value = null;
    },
    getCategories() {
      this.categoryLoading = true;
      axios.get("/admin/category-list").then(({data}) => {
        this.categories = data;
        this.categoryLoading = false;
      });
    },
    getStatuses() {
      this.statusLoading = true;
      axios.get("/admin/status-list").then(({data}) => {
        this.statuses = data;
        this.statusLoading = false;
      });
    },
    typeAction() {
      if (this.form.type == 2) {
        this.getCategories();
      }
      this.form.image = null;
      this.form.status_id = "";
      this.form.category_id = "";
      this.$refs.createImageInput.value = null;
    },
    statusTypeChange() {
      if (!this.statuses.length && this.form.status_type == 2) {
        this.getStatuses();
      }
    },
    editStatusTypeChange() {
      if (!this.statuses.length && this.editForm.status_type == 2) {
        this.getStatuses();
      }
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped></style>
