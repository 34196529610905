<template>
  <main class="justify-center mx-auto">
    <div class="w-full shadow-2xl">
      <div class="p-2 bg-base-100 mx-1">
        <div class="flex flex-col">
          <div class="flex flex-row justify-between p-2 pb-0">
            <div v-if="type == 1" class="card-title">Followers</div>
            <div v-if="type == 2" class="card-title">Following</div>
            <div class="btn btn-accent btn-xs" @click="getData">Refresh</div>
          </div>

        </div>
      </div>
      <div class="card-body p-1 pb-6">
        <div v-if="isLoading" class="mt-5">
          <content-placeholders :centered="true" :rounded="true">
            <content-placeholders-heading/>
            <content-placeholders-heading/>
            <content-placeholders-heading/>
            <content-placeholders-heading/>
            <content-placeholders-heading/>
          </content-placeholders>
        </div>
        <div v-else>
          <div v-if="data.data">
            <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-x-2 gap-y-1">
              <div v-for="(user, index) in data.data" :key="index">
                <div class="card p-2 compact bg-base-100 mx-1 mt-1">
                  <div class="flex-row items-center space-x-2.5 card px-1 pb-1">
                    <div class="flex-1">
                      <div class="flex-row items-center space-x-3 card mt-1 mb-1">
                        <div class="avatar">
                          <div
                              class="rounded-full w-10 h-10 shadow clickable"
                              @click="viewUser(user.username)"
                          >
                            <img
                                v-if="user.image"
                                :src="url + '/' + user.image"
                                :alt="user.name"
                            />
                            <img v-else :src="url + '/user/no-image.png'"/>
                          </div>
                        </div>
                        <div class="flex flex-col space-y-0 items-start">
                          <div
                              class="flex flex-row space-x-1.5 items-center clickable"
                              @click="viewUser(user.username)"
                          >
                            <div class="font-bold text-lg ml-1">
                              {{ user.name }}
                            </div>
                            <div v-if="user.verified">
                              <verification-svg height="17px" width="17px"/>
                            </div>
                          </div>
                          <div class="text-sm font-thin text-opacity-60 ml-1">
                            @{{ user.username }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex-0">
                      <div class="flex-row items-center space-x-2.5 card mt-1 mb-1">
                        <router-link :to="{name: 'user-details',params: { username: user.username },}"
                                     class="btn-secondary btn-sm">View
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="data.total == 0">
            <div
                class="card p-2 shadow-lg compact text-center bg-base-100 mx-1 mt-3 mb-2"
            >
              <h2 class="font-bold text-lg">Nothing to Show</h2>
            </div>
          </div>
        </div>
        <div class="flex justify-center mt-2">
          <pagination class="menu menu-horizontal bg-base-100 rounded-box p-0" :data="data" :limit="3"
                      @pagination-change-page="getData"></pagination>
        </div>
      </div>
    </div>

  </main>
</template>

<script>
import axios from "axios";

export default {
  props: {type: Number},
  beforeCreate() {
    document.title = this.$route.meta.title + " | " + this.$sitename;
  },
  mounted() {
    this.getData();
    this.$Progress.finish();
  },

  data() {
    return {
      url: this.$config.FILE_URL,
      data: {
        total: null,
      },
      isLoading: false,
      errors: {},
    };
  },
  watch: {
    type() {
      this.getData();
    }
  },
  methods: {
    async getData(page = 1) {
      this.isLoading = true;
      let username = this.$route.params.username;
      let url = null;
      if (this.type == 1) {
        url = '/admin/user-followers';
      } else if (this.type == 2) {
        url = '/admin/user-followings'
      }
      try {
        let r = await axios.get(url, {params: {username: username, page: page}});
        this.data = r.data;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.$noti('error', 'Something went wrong');
      }
    },
    viewUser(username) {
      this.$router.push({
        name: "user-details",
        params: {username: username},
      });
    },

  },
};
</script>

<style scoped></style>