<template>
  <main class="justify-center mx-auto">
    <div class="mx-2 mt-3">
      <div class="flex flex-row w-full justify-between">
        <div
            :class="formType === 1 ? 'tab-active' : ''"
            class="flex-grow tab tab-bordered"
            @click="tabClick(1)"
        >
          Categories
        </div>
        <div
            :class="formType === 2 ? 'tab-active' : ''"
            class="flex-grow tab tab-bordered"
            @click="tabClick(2)"
        >
          Add Category
        </div>
      </div>
    </div>

    <div v-if="formType === 1">
      <div class="w-full shadow-2xl">
        <div class="card-body p-1 pb-6">
          <div v-if="isLoading" class="mt-5">
            <content-placeholders :centered="true" :rounded="true">
              <content-placeholders-heading/>
              <content-placeholders-heading/>
              <content-placeholders-heading/>
              <content-placeholders-heading/>
              <content-placeholders-heading/>
            </content-placeholders>
          </div>
          <div v-else>
            <div v-if="categories.length" class="mt-5">
              <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-3">
                <div v-for="(category, index) in categories" :key="index">
                  <div
                      class="card p-2 shadow-lg compact bg-base-100 mx-1 mt-3 mb-2"
                  >

                    <div class="flex flex-col justify-around space-y-2">

                      <div class="avatar self-center">
                        <div class="w-56 rounded">
                          <img
                              :alt="category.name"
                              :src="url + category.image"
                          />
                        </div>
                      </div>

                      <div class="flex flex-row justify-between mx-1">
                        <div class="font-bold">Name :</div>
                        <div>
                          <h1 v-if="category.name && category.name.length < 80">
                            {{ category.name }}
                          </h1>
                          <h1 v-if="category.name && category.name.length > 80">
                            {{ category.name.substring(0, 80) + "..." }}
                          </h1>
                        </div>
                      </div>

                      <div class="flex flex-row justify-between mx-1">
                        <div class="font-bold">Slug :</div>
                        <div>{{ category.slug }}</div>
                      </div>

                      <div class="flex flex-row justify-between mx-1">
                        <div class="font-bold">Active :</div>
                        <div>
                          <span v-if="category.active" class="badge badge-info"> Yes </span>
                          <span v-else class="badge badge-warning"> No </span>
                        </div>
                      </div>

                    </div>

                    <div
                        class="flex flex-row justify-center space-x-3 p-2 rounded pt-3"
                    >
                      <div
                          class="btn btn-primary btn-sm"
                          @click="editClickAction(category)"
                      >
                        <i class="bx bx-edit"></i>
                      </div>
                      <div
                          v-if="index !== 0"
                          class="btn btn-primary btn-sm"
                          @click="changePosition(category.id, 'up')"
                      >
                        <i class="bx bx-up-arrow"></i>
                      </div>
                      <div
                          v-if="index !== categories.length - 1"
                          class="btn btn-primary btn-sm"
                          @click="changePosition(category.id, 'down')"
                      >
                        <i class="bx bx-down-arrow"></i>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
            <div v-else>
              <div
                  class="card p-2 shadow-lg compact text-center bg-base-100 mx-1 mt-3 mb-2"
              >
                <h2 class="font-bold text-lg">Nothing to Show</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="formType === 2">
      <div class="pt-4 w-full justify-center mx-auto md:w-5/6 shadow-2xl">
        <div class="card-body pt-0">
          <form @submit.prevent="createCategory">
            <div class="form-control mt-5">
              <label class="label">
                <span class="label-text font-bold">Name : </span>
              </label>
              <input
                  v-model="form.name"
                  class="input input-accent"
                  placeholder="Ex: Funny"
                  type="text"
              />
              <small v-if="errors.name" class="text-red-600">{{
                  errors.name[0]
                }}</small>
            </div>
            <div class="form-control mt-2">
              <label class="label">
                <span class="label-text font-bold">Slug : </span>
              </label>
              <input
                  v-model="form.slug"
                  class="input input-accent"
                  placeholder="Ex: funny-meme"
                  type="text"
              />
              <small v-if="errors.slug" class="text-red-600">{{
                  errors.slug[0]
                }}</small>
            </div>

            <div class="form-control mt-3">
              <label class="label">
                <span class="label-text font-bold">Image : </span>
              </label>
              <input
                  accept=".jpg,.png,.jpeg,.heic"
                  class="file"
                  type="file"
                  @change="imageChange"
              />
              <small v-if="errors.image" class="text-red-600">{{
                  errors.image[0]
                }}</small>
            </div>
            <div v-if="form.image" class="form-control mt-4">
              <div class="flex flex-wrap justify-center">
                <div class="w-8/12 sm:w-6/12 px-4">
                  <img
                      :src="form.image"
                      alt="..."
                      class="rounded max-w-full h-auto align-middle border-none"
                  />
                </div>
              </div>
            </div>

            <div class="form-control mt-8">
              <button
                  :disabled="isCreating"
                  class="btn btn-primary"
                  type="submit"
              >
                <svg-loading
                    v-if="isCreating"
                    class="mr-1"
                    height="16px"
                    width="16px"
                />
                Create Category
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <modal
        :if-close="true"
        :showing="editModal"
        @update:modal="editModal = $event"
    >
      <div
          v-if="editModal"
          class="card p-2 shadow-lg compact bg-base-100 mx-1 mt-1"
      >
        <div class="divider">Edit Details</div>

        <form @submit.prevent="updateCategory">
          <div class="form-control mt-5">
            <label class="label">
              <span class="label-text font-bold">Name : </span>
            </label>
            <input
                v-model="editForm.name"
                class="input input-accent"
                placeholder="Ex: Funny"
                type="text"
            />
            <small v-if="errors.name" class="text-red-600">{{
                errors.name[0]
              }}</small>
          </div>

          <div class="form-control mt-2">
            <label class="label">
              <span class="label-text font-bold">Slug : </span>
            </label>
            <input
                v-model="editForm.slug"
                class="input input-accent"
                placeholder="Ex: funny-meme"
                type="text"
            />
            <small v-if="errors.slug" class="text-red-600">{{
                errors.slug[0]
              }}</small>
          </div>

          <div class="form-control mt-2">
            <label class="label">
              <span class="label-text font-bold">Active : </span>
            </label>
            <select v-model="editForm.active" class="select select-accent">
              <option disabled="disabled" selected="selected" value="">
                Choose Active Status
              </option>
              <option value="1">Yes</option>
              <option value="0">No</option>
            </select>
            <small v-if="errors.slug" class="text-red-600">{{
                errors.slug[0]
              }}</small>
          </div>

          <div class="form-control mt-3">
            <label class="label">
              <span class="label-text font-bold">Image : </span>
            </label>
            <input
                accept=".jpg,.png,.jpeg,.heic"
                class="file"
                type="file"
                @change="editImageChange"
            />
            <small v-if="errors.image" class="text-red-600">{{
                errors.image[0]
              }}</small>
          </div>
          <div v-if="editForm.image" class="form-control mt-4">
            <div class="flex flex-wrap justify-center">
              <div class="w-8/12 sm:w-6/12 px-4">
                <img
                    :src="editForm.image"
                    alt="Category Image"
                    class="rounded max-w-full h-auto align-middle border-none"
                />
              </div>
            </div>
          </div>

          <div class="form-control mt-8 mb-3">
            <button
                :disabled="isUpdating"
                class="btn btn-primary"
                type="submit"
            >
              <svg-loading
                  v-if="isUpdating"
                  class="mr-1"
                  height="16px"
                  width="16px"
              />
              Update Category
            </button>
          </div>
        </form>
      </div>
    </modal>
  </main>
</template>

<script>
import {mapActions} from "vuex";
import axios from "axios";

export default {
  beforeCreate() {
    document.title = this.$route.meta.title + " | " + this.$sitename;
  },
  created() {
    this.getCategories();
  },
  mounted() {
    this.$Progress.finish();
  },
  data() {
    return {
      url: this.$config.FILE_URL,
      formType: 1,
      categories: [],
      form: {
        name: null,
        slug: null,
        image: null,
        active: "",
      },
      editForm: {
        id: null,
        name: null,
        slug: null,
        image: null,
        active: "",
      },
      isLoading: false,
      isCreating: false,
      isUpdating: false,
      errors: {},
      editModal: false,
    };
  },
  methods: {
    getCategories() {
      this.isLoading = true;
      axios
          .get("/admin/categories")
          .then(({data}) => {
            this.categories = data;
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            this.errors = error.response.data.errors;
          });
    },
    tabClick(id) {
      if (this.formType == 1 && id == 1) {
        this.getCategories();
      }
      this.formType = id;
    },

    editClickAction(data) {
      try {
        this.editForm = {
          id: data.id,
          name: data.name,
          slug: data.slug,
          active: data.active,
        };
      } finally {
        this.editModal = true;
      }
    },
    imageChange(event) {
      let file = event.target.files[0];
      let reader = new FileReader();
      reader.onload = (event) => {
        this.form.image = event.target.result;
      };
      reader.readAsDataURL(file);
    },
    editImageChange(event) {
      let file = event.target.files[0];
      let reader = new FileReader();
      reader.onload = (event) => {
        this.editForm.image = event.target.result;
      };
      reader.readAsDataURL(file);
    },
    createCategory() {
      this.isCreating = true;
      this.errors = {};
      axios
          .post("/admin/create-category", this.form)
          .then(({data}) => {
            this.isCreating = false;
            this.resetForm();
            this.getCategories();
            this.$noti("success", data.message);
          })
          .catch((error) => {
            this.isCreating = false;
            if (error.response.status === 406) {
              this.$noti("warning", error.response.data.message);
            } else {
              this.errors = error.response.data.errors;
              this.$noti("error", "Something Went Wrong !");
            }
          });
    },
    updateCategory() {
      this.isUpdating = true;
      this.errors = {};
      axios
          .patch("/admin/update-category", this.editForm)
          .then(({data}) => {
            this.isUpdating = false;
            this.editForm = {
              id: null,
              name: null,
              slug: null,
              image: null,
              active: "",
            };
            this.getCategories();
            this.editModal = false;
            this.$noti("success", data.message);
          })
          .catch((error) => {
            this.isUpdating = false;
            if (error.response.status === 404) {
              this.$noti("warning", error.response.data.message);
            } else {
              this.errors = error.response.data.errors;
              this.$noti("error", "Something Went Wrong !");
            }
          });
    },
    changePosition(id, type) {
      axios
          .patch("/admin/category-position", {id: id, type: type})
          .then(({data}) => {
            this.getCategories();
            this.$noti("success", data.message);
          })
          .catch((error) => {
            if (error.response.status === 404) {
              this.$noti("warning", error.response.data.message);
            } else {
              this.errors = error.response.data.errors;
              this.$noti("error", "Something Went Wrong !");
            }
          });
    },

    resetForm() {
      this.form = {name: null, slug: null, image: null, active: ""};
    },

    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped></style>