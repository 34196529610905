<template>
  <main class="bg-base-200">
    <div class="drawer drawer-mobile">
      <input id="my-drawer-2" class="drawer-toggle" type="checkbox" />
      <div class="drawer-content">
        <!-- Page content here -->
        <header class="nav-bar sticky top-0 bg-base-100 hidden lg:block">
          <div class="flex flex-row justify-end mb-2 shadow-lg">
            <div class="mx-1 space-x-2">
              <div class="flex flex-row space-x-2 items-center justify-center">
                <div class="dropdown dropdown-end">
                  <div class="btn btn-ghost btn-square" tabindex="0">
                    <i
                        class="bx bx-dots-vertical text-2xl sm:text-4xl font-semibold"
                    ></i>
                  </div>
                  <ul class="p-2 shadow menu dropdown-content bg-base-100 rounded-box w-52" tabindex="0">

                    <li class="card text-base-content w-full p-2 shadow compact mt-1 mb-1 clickable" @click="dropdownClickAction('profile')">
                      <div class="flex flex-row space-x-1 items-center">
                        <div><i class="bx bx-search"></i></div>
                        <div>Profile</div>
                      </div>
                    </li>
                    <li class="card text-base-content w-full p-2 shadow compact mb-1 clickable" @click="dropdownClickAction('theme')">
                      <div class="flex flex-row space-x-1 items-center">
                        <div><i class="bx bxs-palette"></i></div>
                        <div>Change Theme</div>
                      </div>
                    </li>
                    <li class="card text-base-content w-full p-2 shadow compact mb-1 clickable" @click="signOut">
                      <div class="flex flex-row space-x-1 items-center">
                        <div><i class="bx bx-log-out"></i></div>
                        <div>Logout</div>
                      </div>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>

        <header class="sticky top-0 z-50 lg:hidden">
          <div class="navbar mb-2 shadow-lg bg-neutral text-neutral-content">
            <div class="mx-1 navbar-start">
              <button>
                <label for="my-drawer-2"
                  ><i
                    class="clickable bx bx-menu text-2xl sm:text-4xl font-bold"
                  ></i
                ></label>
              </button>
            </div>
            <div class="px-2 mx-2 navbar-center">
              <div class="flex items-stretch items-center">
                <div class="flex items-center">
                  <img class="h-8 w-8" :src="url + '/logo.png' " />
                </div>
                <div class="flex items-center">
                  <div v-if="siteName.length <= 8" class="text-3xl text-base-content font-bold ml-2">{{ siteName }}</div>
                  <div v-else class="text-xl text-base-content font-bold ml-2">{{ siteName }}</div>
                </div>
              </div>
            </div>
            <div class="mx-1 navbar-end space-x-2">
              <div class="flex flex-row space-x-2 items-center justify-center">
                <div class="dropdown dropdown-end">
                  <div class="btn btn-ghost btn-square" tabindex="0">
                    <i
                      class="bx bx-dots-vertical text-2xl sm:text-4xl font-semibold"
                    ></i>
                  </div>
                  <ul class="p-2 shadow menu dropdown-content bg-base-100 rounded-box w-52" tabindex="0">

                    <li class="card text-base-content w-full p-2 shadow compact mt-1 mb-1 clickable" @click="dropdownClickAction('profile')">
                      <div class="flex flex-row space-x-1 items-center">
                        <div><i class="bx bx-search"></i></div>
                        <div>Profile</div>
                      </div>
                    </li>
                    <li class="card text-base-content w-full p-2 shadow compact mb-1 clickable" @click="dropdownClickAction('theme')">
                      <div class="flex flex-row space-x-1 items-center">
                        <div><i class="bx bxs-palette"></i></div>
                        <div>Change Theme</div>
                      </div>
                    </li>
                    <li class="card text-base-content w-full p-2 shadow compact mb-1 clickable" @click="signOut">
                      <div class="flex flex-row space-x-1 items-center">
                        <div><i class="bx bx-log-out"></i></div>
                        <div>Logout</div>
                      </div>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div
          class="container mx-auto min-h-screen rounded-b bg-base-200 lg:pt-2 lg:pl-2"
          style="padding-bottom: 60px"
        >
          <div class="mx-1">
            <router-view></router-view>
          </div>
        </div>

        <modal
            :if-close="true"
            :showing="showModal"
            @update:modal="showModal = $event"
        >
          <div v-if="modalType === 1">
            <theme-modal />
          </div>
          <div v-if="modalType === 2">
            <language-modal @update:status="showModal = $event" />
          </div>
        </modal>

      </div>
      <div class="drawer-side min-h-screen">
        <label class="drawer-overlay" for="my-drawer-2"></label>
        <div
          class="menu p-4 overflow-y-auto w-60 md:w-80 bg-base-100 text-primary-content"
        >
          <div class="flex flex-col space-y-2.5 items-start font-semibold">

            <div class="card rounded-none w-full p-2 pt-0 shadow-lg compact mb-3">
              <div class="flex flex-row items-stretch space-x-2 items-center">
                <div>
                  <img class="h-10 w-10" :src="url + '/logo.png' " />
                </div>
                <div class="text-2xl md:text-3xl ml-2 text-base-content">{{ siteName }}</div>
              </div>
            </div>

            <div class="card rounded-md w-full p-2 shadow-lg compact bg-primary mt-5">
              <router-link :to="{ name: 'dashboard' }" class="flex flex-row space-x-2 items-center">
                <div class="text-xl"><i class="bx bx-grid-alt"></i></div>
                <div>
                  <div class="text-xl ml-2">Dashboard</div>
                </div>
              </router-link>
            </div>

            <div class="card rounded-md w-full p-2 shadow-lg compact bg-primary mt-5">
              <router-link :to="{ name: 'users' }" class="flex flex-row space-x-2 items-center">
                <div class="text-xl"><i class="bx bx-group"></i></div>
                <div>
                  <div class="text-xl ml-2">Users</div>
                </div>
              </router-link>
            </div>

            <div
              class="card rounded-md w-full p-2 shadow-lg compact bg-primary mt-5"
            >
              <router-link
                :to="{ name: 'status' }"
                class="flex flex-row space-x-2 items-center"
              >
                <div class="text-xl"><i class="bx bx-book-content"></i></div>
                <div>
                  <div class="text-xl ml-2">Status</div>
                </div>
              </router-link>
            </div>

            <div
              class="card rounded-md w-full p-2 shadow-lg compact bg-primary mt-5"
            >
              <router-link
                :to="{ name: 'status-review' }"
                class="flex flex-row space-x-2 items-center"
              >
                <div class="text-xl"><i class="bx bx-book-reader"></i></div>
                <div>
                  <div class="text-xl ml-2">Status Review</div>
                </div>
              </router-link>
            </div>

            <div
              class="card rounded-md w-full p-2 shadow-lg compact bg-primary mt-5"
            >
              <router-link
                :to="{ name: 'ads' }"
                class="flex flex-row space-x-2 items-center"
              >
                <div class="text-xl"><i class="bx bx-dollar"></i></div>
                <div>
                  <div class="text-xl ml-2">Advertisements</div>
                </div>
              </router-link>
            </div>

            <div
              class="card rounded-md w-full p-2 shadow-lg compact bg-primary mt-5"
            >
              <router-link
                :to="{ name: 'categories' }"
                class="flex flex-row space-x-2 items-center"
              >
                <div class="text-xl"><i class="bx bxs-category"></i></div>
                <div>
                  <div class="text-xl ml-2">Categories</div>
                </div>
              </router-link>
            </div>

            <div
              class="card rounded-md w-full p-2 shadow-lg compact bg-primary mt-5"
            >
              <router-link
                :to="{ name: 'slides' }"
                class="flex flex-row space-x-2 items-center"
              >
                <div class="text-xl"><i class="bx bxs-slideshow"></i></div>
                <div>
                  <div class="text-xl ml-2">Slides</div>
                </div>
              </router-link>
            </div>

            <div
              class="card rounded-md w-full p-2 shadow-lg compact bg-primary mt-5"
            >
              <router-link
                :to="{ name: 'languages' }"
                class="flex flex-row space-x-2 items-center"
              >
                <div class="text-xl"><i class="bx bx-globe"></i></div>
                <div>
                  <div class="text-xl ml-2">Languages</div>
                </div>
              </router-link>
            </div>

            <div
              class="card rounded-md w-full p-2 shadow-lg compact bg-primary mt-5"
            >
              <router-link
                :to="{ name: 'currencies' }"
                class="flex flex-row space-x-2 items-center"
              >
                <div class="text-xl"><i class="bx bx-money"></i></div>
                <div>
                  <div class="text-xl ml-2">Currencies</div>
                </div>
              </router-link>
            </div>

            <div
              class="card rounded-md w-full p-2 shadow-lg compact bg-primary mt-5"
            >
              <router-link
                :to="{ name: 'withdrawals' }"
                class="flex flex-row space-x-2 items-center"
              >
                <div class="text-xl"><i class="bx bx-money-withdraw"></i></div>
                <div>
                  <div class="text-xl ml-2">Withdrawals</div>
                </div>
              </router-link>
            </div>

            <div
              class="card rounded-md w-full p-2 shadow-lg compact bg-primary mt-5"
            >
              <router-link
                :to="{ name: 'withdrawal-methods' }"
                class="flex flex-row space-x-2 items-center"
              >
                <div class="text-xl"><i class="bx bxs-wallet"></i></div>
                <div>
                  <div class="text-xl ml-2">Withdrawal Methods</div>
                </div>
              </router-link>
            </div>

            <div
              class="card rounded-md w-full p-2 shadow-lg compact bg-primary mt-5"
            >
              <router-link
                :to="{ name: 'deposits' }"
                class="flex flex-row space-x-2 items-center"
              >
                <div class="text-xl"><i class="bx bxs-bank"></i></div>
                <div>
                  <div class="text-xl ml-2">Deposits</div>
                </div>
              </router-link>
            </div>

            <div
              class="card rounded-md w-full p-2 shadow-lg compact bg-primary mt-5"
            >
              <router-link
                :to="{ name: 'deposit-methods' }"
                class="flex flex-row space-x-2 items-center"
              >
                <div class="text-xl"><i class="bx bx-wallet-alt"></i></div>
                <div>
                  <div class="text-xl ml-2">Deposit Methods</div>
                </div>
              </router-link>
            </div>

            <div class="card rounded-md w-full p-2 shadow-lg compact bg-primary mt-5">
              <router-link :to="{ name: 'reports' }" class="flex flex-row space-x-2 items-center">
                <div class="text-xl"><i class="bx bxs-report"></i></div>
                <div>
                  <div class="text-xl ml-2">Reports</div>
                </div>
              </router-link>
            </div>

            <div class="card rounded-md w-full p-2 shadow-lg compact bg-primary mt-5">
              <router-link :to="{ name: 'support-tickets' }" class="flex flex-row space-x-2 items-center">
                <div class="text-xl"><i class="bx bx-support"></i></div>
                <div>
                  <div class="text-xl ml-2">Support Tickets</div>
                </div>
              </router-link>
            </div>

            <div class="card rounded-md w-full p-2 shadow-lg compact bg-primary mt-5">
              <router-link :to="{ name: 'settings' }" class="flex flex-row space-x-2 items-center">
                <div class="text-xl"><i class="bx bx-cog"></i></div>
                <div>
                  <div class="text-xl ml-2">Settings</div>
                </div>
              </router-link>
            </div>



          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import { mapActions } from "vuex";

export default {
  computed: {
    url() {
      return this.$config.FILE_URL;
    },
    siteName() {
      return this.$sitename;
    },
  },
  data() {
    return {
      modalType: 0,
      showModal: false,
    };
  },
  methods: {
    ...mapActions({
      signOutAction: "signOut",
    }),
    signOut() {
      this.signOutAction().then(() => {
        this.$router.replace({ name: "login" });
      });
    },
    dropdownClickAction(type) {
      if (type === "profile") {
        this.$router.push("/profile");
      } else if (type === "theme") {
        this.modalType = 1;
        this.showModal = true;
      } else if (type === "language") {
        this.modalType = 2;
        this.showModal = true;
      }
    },
  },
};
</script>

<style scoped>
.nav-bar {
  left: 320px;
  z-index: 98;
}
</style>
